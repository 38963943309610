import { Component, Input, ViewChild, ElementRef, Output, EventEmitter, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'sbdl-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  @Input() containerClass = '';
  @Input() scrollAmount = 370;
  @Input() showButtons = true;
  @Input() autoScroll = false;
  @Input() autoScrollDelay = 500;

  @ViewChild('scrollContent') scrollContent!: ElementRef;
  @Output() scrollChange = new EventEmitter<{left: number, width: number}>();

  private resizeTimeout: any;

  ngOnInit() {
    if (this.autoScroll) {
      setTimeout(() => {
        this.scrollRight(true);
      }, this.autoScrollDelay);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.updateScrollState();
    }, 100);
  }

  scrollLeft() {
    if (this.scrollContent) {
      this.scrollContent.nativeElement.scrollTo({
        left: this.scrollContent.nativeElement.scrollLeft - this.scrollAmount,
        behavior: 'smooth'
      });
      this.updateScrollState();
    }
  }

  scrollRight(shortened = false) {
    if (this.scrollContent) {
      const scrollAmount = shortened ? this.scrollAmount - 47 : this.scrollAmount;
      this.scrollContent.nativeElement.scrollTo({
        left: this.scrollContent.nativeElement.scrollLeft + scrollAmount,
        behavior: 'smooth'
      });
      this.updateScrollState();
    }
  }

  private updateScrollState() {
    if (this.scrollContent) {
      this.scrollChange.emit({
        left: this.scrollContent.nativeElement.scrollLeft,
        width: this.scrollContent.nativeElement.scrollWidth
      });
    }
  }
}
