import {APP_BASE_HREF} from '@angular/common';
import {Component, OnInit, Inject} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Observable, } from 'rxjs';
import {AppConfig} from 'src/app/common/config/app.config';
import {UserService} from 'src/app/data/user/user.service';
import {TenantThemeService} from '../../../data/tenant-theme/tenant-theme.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {NavVisibilityService} from '../../../common/nav-visbility.service';

@Component({
  selector: 'sbdl-small-navigation',
  templateUrl: './small-navigation.component.html',
  styleUrls: ['./small-navigation.component.scss'],
  animations: [
    trigger('slideLeft', [
      state('visible', style({
        transform: 'translateX(0)',
      })),
      state('hidden', style({
        transform: 'translateX(-100%)',
      })),
      transition('visible <=> hidden', [
        animate('0.5s ease-in-out')
      ])
    ])
  ]
})
export class SmallNavigationComponent implements OnInit {

  logo$: Observable<string>;
  interimItemPortalUrl = '#';
  hasIaipAccess$: Observable<boolean>;
  isAuthenticated: boolean;
  closed: boolean;
  science = false;
  navVisibilityState = 'closed';

  constructor(
    @Inject(APP_BASE_HREF) public baseHref: string,
    private userService: UserService,
    private titleService: Title,
    private tenantThemeService: TenantThemeService,
    private navVisibilityService: NavVisibilityService
  ) {
    this.hasIaipAccess$ = userService.hasIaipRole;
  }

  ngOnInit() {
    this.interimItemPortalUrl = AppConfig.settings.interimItemPortalUrl;
    this.userService.authenticated.subscribe(data => {
      this.isAuthenticated = data;
    });
    this.tenantThemeService.currentTenantTheme$.subscribe(
      theme => {
        if (theme.displayName === 'California' || theme.displayName === 'Oregon') {
          this.science = true;
        }
      }
    );
    this.navVisibilityService.currentVisibility.subscribe(visibility => {
      this.navVisibilityState = visibility;
    });
  }
  setTitle(title: string) {
    this.titleService.setTitle(title);
  }

  toggleNav(navVisibiltyState) {
    this.navVisibilityService.toggleNavVisibility(navVisibiltyState);
  }

  onKeydown(event: KeyboardEvent, navState: string): void {
    if (event.key === 'Enter' || event.code === 'Enter') {
      this.toggleNav(navState);
    }
  }
}
