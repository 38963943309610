<nav class="nav-show-hide-container small-nav-container" [ngClass]="{ 'show': navVisibilityState !== 'closed' }">
  <ul class="nav-links nav-lessons">
    <sbdl-nav-link
      title="Home"
      ariaLabel="Home Small Nav"
      icon="far fa-home-alt"
      class="small-nav-link"
      [tabIndexLink]="'3'"
      (keydown)="onKeydown($event, 'closed')"
      [linkDestination]="'/'"
      (click)="toggleNav('closed')"
      [specialClass]="'home-link'"
    ></sbdl-nav-link>
    <sbdl-nav-link
      title="Lesson Planning"
      ariaLabel="Lesson Planning"
      icon="far fa-chalkboard-teacher"
      class="small-nav-link"
      [tabIndexLink]="'4'"
      (keydown)="onKeydown($event, 'lesson')"
      (click)="toggleNav('lesson')"
      [ngClass]="{ 'active': navVisibilityState === 'lesson' }"
    ></sbdl-nav-link>
    <sbdl-nav-link
      title="Professional Learning"
      ariaLabel="Professional Learning"
      [sbdlIcon]="'goal'"
      class="small-nav-link"
      [tabIndexLink]="'6'"
      (keydown)="onKeydown($event, 'professional')"
      (click)="toggleNav('professional')"
      [ngClass]="{ 'active': navVisibilityState === 'professional' }"
    ></sbdl-nav-link>
    <sbdl-nav-link
      title="Assessment Tools"
      ariaLabel="Assessment Tools"
      icon="far fa-lightbulb-on"
      class="small-nav-link"
      [tabIndexLink]="'8'"
      (keydown)="onKeydown($event, 'assessment')"
      (click)="toggleNav('assessment')"
      [ngClass]="{ 'active': navVisibilityState === 'assessment' }"
    ></sbdl-nav-link>
  </ul>

    <div class="nav-links nav-feedback" *ngIf="isAuthenticated === true">
      <a href="#" tabindex="9" id="feedback-element" class="feedback-element-link">
        <div class="nav-feedback-text">
          <div class="nav-feedback-icon"><i class="far fa-comment-alt-exclamation"></i></div>
          <div class="header-text feedback-note">Feedback</div>
        </div>
      </a>
    </div>
</nav>
